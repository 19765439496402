//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

//bootstrapのmodalを読み込み
// import "/node_modules/bootstrap/js/dist/modal";

import $ from "jquery";
const $ = require('jquery');
$(function () {
	$('a[href*=\\#]:not([href=\\#])').on('click', function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var $target = $(this.hash);
			$target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
			if ($target.length) {
				if ($(this).parents('.menuBox').length) {
					setTimeout(function () {
						var targetOffset = $target.offset().top;
						$('html,body').animate({ scrollTop: targetOffset }, 1000);
					}, 100);
				} else {
					var targetOffset = $target.offset().top;
					$('html,body').animate({ scrollTop: targetOffset }, 1000);
				}
				return false;
			}
		}
	});

	var state = false;
	var scrollpos;
	$('.menu').on('click', function() {
		if (state == false) {
			scrollpos = $(window).scrollTop();
			$('body').addClass('fixed').css({ 'top': -scrollpos });
			$('.menu, .menuBox').addClass('on');
			state = true;
		} else {
			$('body').removeClass('fixed').css({ 'top': 0 });
			window.scrollTo(0, scrollpos);
			$('.menu, .menuBox').removeClass('on');
			state = false;
		}
	});

	$('.menuBox a').on('click', function() {
		if(window.innerWidth < 897) {
			$('body').removeClass('fixed').css({ 'top': 0 });
			window.scrollTo(0, scrollpos);
			$('.menu, .menuBox').removeClass('on');
			state = false;
		}
	});
});

$(window).on('load', function () {
	var localLink = window.location + '';
	if (localLink.indexOf("#") != -1 && localLink.slice(-1) != '#') {
		localLink = localLink.slice(localLink.indexOf("#") + 1);
		$('html,body').animate({ scrollTop: $('#' + localLink).offset().top }, 500);
	}
});

$(function() {
    $('#main .staff .staffList li .name').matchHeight();
    $(window).on('resize', function() {
        if (window.innerWidth < 897) {
            $('.mainVisual .innerBox .photo').insertAfter($('.mainVisual .innerBox .textBox .enTtl'));
        } else {
            $('.mainVisual .innerBox .photo').insertAfter($('.mainVisual .innerBox .textBox'));
        }
    }).trigger('resize');
})